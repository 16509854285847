import {useEffect, useState} from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    Chip, CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
    TextField
} from "@mui/material";
import {DateTimePicker} from "@mui/x-date-pickers";

import {
    QuizStatusType,
    QuizUserGroupInfo,
    useAddQuizMutation,
    useGetQuizQuery,
    useUpdateQuizMutation
} from "../../api/quiz";
import {useGetAllUserGroupsAdminQuery} from "../../api/usergroups";
import {ErrorAlert} from "../ErrorAlert";
import {t} from "../../i18n/i18n";

interface MyProps {
    quizid?: number;
    open: boolean;
    userGroupIsRequired: boolean;
    close: (quizId: number, quizQuestionsType: string) => any;
    cancel: () => any;
}

const formInitialState = {
    id: undefined as number | undefined,
    status: 'DRAFT' as QuizStatusType,
    title: '',
    instructions: '',
    durationMinutes: 20,
    passingPercent: 80,
    maxAttempts: 0,
    quizQuestionsType: '',
    questionsRandomOrder: false,
    answersRandomOrder: false,
    displayAnswerSheet: false,
    start: dayjs().toISOString(),
    end: null as string | null,
    userGroups: [] as QuizUserGroupInfo[]
};

export const EditQuizDialog = ({quizid, open, close, cancel, userGroupIsRequired}: MyProps) => {
    const [formData, setFormData] = useState(formInitialState);
    const {data, isSuccess, isFetching, isLoading, isError, error} = useGetQuizQuery(quizid || 0, {skip: quizid === undefined});
    const {
        data: userGroupsData,
        isSuccess: userGroupsSuccess,
        isLoading: groupsLoading,
        isError: groupsIsError,
        error: groupsError,
    } = useGetAllUserGroupsAdminQuery();
    const [addQuiz, addStatus] = useAddQuizMutation();
    const [updateQuiz, updateStatus] = useUpdateQuizMutation();

    dayjs.extend(utc);
    dayjs.extend(timezone);

    useEffect(() => {
        if (quizid === undefined) {
            setFormData(formInitialState);
        } else if (!isFetching && isSuccess) {
            setFormData(data);
        }
    }, [quizid, data, isSuccess, isFetching])

    const handleSave = () => {
        if (formData.id === undefined) {
            addQuiz(formData).unwrap().then((result) => {
                setFormData(formInitialState);
                close(result.id, result.quizQuestionsType);
            });
        } else {
            // @ts-ignore
            updateQuiz(formData).unwrap().then(() => {
                const qId = formData.id;
                const qType = formData.quizQuestionsType;
                setFormData(formInitialState);
                close(qId ?? 0, qType);
            });
        }
    }

    const handleCancel = () => {
        setFormData(formInitialState);
        cancel();
    }

    const handleChangeUserGroups = (event: SelectChangeEvent<QuizUserGroupInfo[]>) => {
        const liste = event.target.value as QuizUserGroupInfo[];

        setFormData({
            ...formData,
            userGroups: [...liste]
        })
    }

    const saveAllowed = () => {
        return formData.title.trim().length > 0 &&
            formData.instructions.trim().length > 0 &&
            (!userGroupIsRequired || formData.userGroups.length > 0) &&
            formData.quizQuestionsType !== '';
    }

    const userGroupsDataSorted = [...(userGroupsData ?? [])];
    userGroupsDataSorted.sort((a, b) => a.title.localeCompare(b.title, 'no'));

    return (
        <Dialog open={open} onClose={handleCancel} maxWidth={'lg'} fullWidth>
            {isError && <ErrorAlert title={t('apiError.getQuiz')} error={error} />}
            {groupsIsError && <ErrorAlert title={t('apiError.getGroupsInfo')} error={groupsError} />}
            {addStatus.isError && <ErrorAlert title={t('apiError.addQuiz')} error={addStatus.error} />}
            {updateStatus.isError && <ErrorAlert title={t('apiError.updateQuiz')} error={updateStatus.error} />}

            {(isLoading || groupsLoading) && <CircularProgress />}

            <DialogTitle>{formData.id !== undefined
                ? t('admin.quiz.edit.title') + formData.id
                : t('admin.quiz.add.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('admin.quiz.edit.instructions')}
                </DialogContentText>
                <Box component={'form'}
                     sx={{
                         display: 'grid',
                         gridTemplateColumns: {sm: '1fr'},
                         gap: 2
                     }}
                     noValidate
                     autoComplete={'off'}>
                    <FormControl fullWidth>
                        <TextField
                            id={'title'}
                            margin={'dense'}
                            label={t('admin.quiz.edit.form.title')}
                            fullWidth
                            variant={'standard'}
                            required
                            error={formData.title.trim().length === 0}
                            value={formData.title}
                            onChange={e => setFormData({...formData, title: e.target.value})}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <TextField
                            id={'instructions'}
                            margin={'dense'}
                            label={t('admin.quiz.edit.form.instructions')}
                            multiline
                            minRows={5}
                            fullWidth
                            variant={'standard'}
                            required
                            error={formData.instructions.trim().length === 0}
                            value={formData.instructions}
                            onChange={e => setFormData({...formData, instructions: e.target.value})}
                        />
                    </FormControl>
                    <FormControl fullWidth>
                        <InputLabel id={'statusLabel'}>{t('admin.quiz.edit.form.status.label')}</InputLabel>
                        <Select
                            id={'status'}
                            readOnly
                            label={t('admin.quiz.edit.form.status.label')}
                            labelId={'statusLabel'}
                            value={formData.status}
                            onChange={e => setFormData({
                                ...formData,
                                status: e.target.value as QuizStatusType
                            })}
                        >
                            <MenuItem value={'DRAFT'}>{t('generic.quizStatus.draft')}</MenuItem>
                            <MenuItem value={'ACTIVE'}>{t('generic.quizStatus.active')}</MenuItem>
                            <MenuItem value={'FINISHED'}>{t('generic.quizStatus.finished')}</MenuItem>
                            <MenuItem value={'ARCHIVED'}>{t('generic.quizStatus.archived')}</MenuItem>
                            <MenuItem value={'DELETED'}>{t('generic.quizStatus.deleted')}</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl
                        fullWidth
                        required={userGroupIsRequired}
                        error={userGroupIsRequired && formData.userGroups.length === 0}
                    >
                        <InputLabel id={'userGroupsLabel'}>{t('admin.quiz.edit.form.userGroups.label')}</InputLabel>
                        <Select
                            id={'userGroups'}
                            label={t('admin.quiz.edit.form.userGroups.label')}
                            labelId={'userGroupsLabel'}
                            fullWidth
                            multiple
                            disabled={!userGroupsSuccess}
                            value={formData.userGroups}
                            onChange={handleChangeUserGroups}
                            input={<OutlinedInput id="select-multiple-chip" label="Chip"/>}
                            renderValue={(selected) => (
                                <Box sx={{display: 'flex', flexWrap: 'wrap', gap: 0.5}}>
                                    {selected.map((value) => (
                                        <Chip
                                            key={value.id}
                                            label={value.title}
                                        />
                                    ))}
                                </Box>
                            )}
                        >
                            {userGroupsData !== undefined && userGroupsDataSorted.map((group) => {
                                const value: QuizUserGroupInfo = {
                                    id: group.id,
                                    title: group.title
                                };
                                return (
                                    <MenuItem
                                        key={group.id}
                                        value={value as any}
                                    >
                                        <Checkbox checked={formData.userGroups.map(g => g.id).indexOf(group.id) >= 0}/>
                                        <ListItemText primary={group.title}/>
                                    </MenuItem>
                                );
                            })}
                        </Select>
                        {!userGroupIsRequired && (
                            <FormHelperText>{t('admin.quiz.edit.form.userGroups.instructions')}</FormHelperText>
                        )}
                    </FormControl>
                    <FormControl fullWidth>
                        <Stack direction={'row'} spacing={3}>
                            <TextField
                                id={'durationMinutes'}
                                margin={'dense'}
                                label={t('admin.quiz.edit.form.duration.label')}
                                fullWidth
                                variant={'standard'}
                                type={'number'}
                                inputProps={{ min: 0, max: 90 }}
                                value={formData.durationMinutes}
                                onChange={e => setFormData({
                                    ...formData,
                                    durationMinutes: e.target.value as unknown as number
                                })}
                            />
                            <TextField
                                id={'passingPercent'}
                                margin={'dense'}
                                label={t('admin.quiz.edit.form.passingPercent.label')}
                                fullWidth
                                variant={'standard'}
                                type={'number'}
                                inputProps={{ min: 0, max: 100 }}
                                value={formData.passingPercent}
                                onChange={e => setFormData({
                                    ...formData,
                                    passingPercent: e.target.value as unknown as number
                                })}
                            />
                            <TextField
                                id={'maxAttempts'}
                                margin={'dense'}
                                label={t('admin.quiz.edit.form.maxAttempts.label')}
                                helperText={t('admin.quiz.edit.form.maxAttempts.helper')}
                                fullWidth
                                variant={'standard'}
                                type={'number'}
                                inputProps={{ min: 0, max: 50 }}
                                value={formData.maxAttempts}
                                onChange={e => setFormData({
                                    ...formData,
                                    maxAttempts: e.target.value as unknown as number
                                })}
                            />
                        </Stack>
                    </FormControl>
                    <FormControl fullWidth error={formData.quizQuestionsType === ''}>
                        <InputLabel id={'quizQuestionsTypeLabel'}>{t('admin.quiz.edit.form.type.label')}</InputLabel>
                        <Select
                            id={'quizQuestionsType'}
                            label={t('admin.quiz.edit.form.type.label')}
                            labelId={'quizQuestionsTypeLabel'}
                            value={formData.quizQuestionsType}
                            onChange={e => setFormData({
                                ...formData,
                                quizQuestionsType: e.target.value
                            })}
                            inputProps={{readOnly: formData.id !== undefined}}
                        >
                            <MenuItem value={'PREDEFINED'}>{t('admin.quiz.edit.form.type.option.predefined')}</MenuItem>
                            <MenuItem value={'RANDOM_SUBJECT_LEVEL'}>{t('admin.quiz.edit.form.type.option.randomSubjectLevel')}</MenuItem>
                        </Select>
                        <FormControlLabel control={
                            <Checkbox
                                onChange={e => setFormData({...formData, questionsRandomOrder: e.target.checked})}
                                checked={formData.questionsRandomOrder}
                            />
                        } label={t('admin.quiz.edit.form.questionsRandomOrder.label')}/>
                        <FormControlLabel control={
                            <Checkbox
                                onChange={e => setFormData({...formData, answersRandomOrder: e.target.checked})}
                                checked={formData.answersRandomOrder}
                            />
                        } label={t('admin.quiz.edit.form.answersRandomOrder.label')}/>
                        <Stack direction={'row'} spacing={3}>
                            <DateTimePicker
                                label={t('admin.quiz.edit.form.startDateTime.label')}
                                value={dayjs(formData.start)}
                                format={'YYYY-MM-DDTHH:mm'}
                                ampm={false}
                                onChange={value => setFormData({
                                    ...formData,
                                    start: value !== null ? value.toISOString() : ''
                                })}
                            />
                            <DateTimePicker
                                label={t('admin.quiz.edit.form.endDateTime.label')}
                                value={formData.end !== null ? dayjs(formData.end) : null}
                                format={'YYYY-MM-DDTHH:mm'}
                                ampm={false}
                                onChange={value => setFormData({
                                    ...formData,
                                    end: value !== null ? value.toISOString() : ''
                                })}
                            />
                        </Stack>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    disabled={!saveAllowed() || isLoading || groupsLoading || addStatus.isLoading || updateStatus.isLoading}
                    onClick={handleSave}
                >{t('admin.quiz.edit.form.button.saveAndEdit')}</Button>
                <Button onClick={handleCancel}>{t('generic.button.cancel')}</Button>
            </DialogActions>
            <Backdrop open={addStatus.isLoading || updateStatus.isLoading}>
                <CircularProgress/>
            </Backdrop>
        </Dialog>
    );
};
